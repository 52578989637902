// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

/*export const environment = {
  host: "https://sandbox.ipay88.com.ph/epayment/entry.asp",
  Lang: "UTF-8",
  MerchCode: "PH00261",
  currency: "PHP",
  Remark: "",
  ResponseUrl: "https://api.mona.insureshop.ph/api/payment/response",
  BackendUrl: "https://api.mona.insureshop.ph/api/payment/response",
  ProductDesc: "Heartbreak Insurance",
  apiUrl: "https://api.mona.insureshop.ph",
  terms: "https://www.insureshop.ph/terms-conditions",
  production: true,
  productUrl: "http://cybris-052020.web.app"
};*/

export const environment = {
  //host: "https://payment.ipay88.com.ph/epayment/entry.asp",
  host: "https://sandbox.ipay88.com.ph/epayment/entry.asp",
  Lang: "UTF-8",
  MerchCode: "PH00261",//"PH00261PH00513",
  currency: "PHP",
  Remark: "",
  //"ResponseUrl": "https://us-central1-saphron.cloudfunctions.net/paymentResponse",
  //"BackendUrl": "https://us-central1-saphron.cloudfunctions.net/paymentResponse",
  //"GetTransactionUrl": "https://us-central1-saphron.cloudfunctions.net/getTransaction",
  ResponseUrl: "https://us-central1-saphron.cloudfunctions.net/stagingPaymentResponse",
  BackendUrl: "https://us-central1-saphron.cloudfunctions.net/stagingPaymentResponse",
  GetTransactionUrl: "https://us-central1-saphron.cloudfunctions.net/stagingGetTransaction",
  ProductDesc: "Cybris Insurance",
  apiUrl: "https://us-central1-saphron.cloudfunctions.net",
  terms: "https://www.insureshop.ph/terms-conditions",
  //production: true,
  production: false,
  //productUrl: "https://cybris.ph"
  productUrl: "https://cybris-staging.web.app"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
